<template>
  <div class="contianer">
    <h4>
      <b-icon icon="archive" variant="primary"></b-icon>
      File Cabinet
    </h4>
    <h6>{{ pws_label }}</h6>
    <b-card no-body>
      <b-tabs @input="onTabChanged" card>
        <b-tab title="Annual Reports">
          <b-card-text>
            <ul class="file-list">
              <li v-for="annual_report in annual_reports" :key="annual_report.id">
                &nbsp;<b-icon icon="file-text" />
                <span>Annual Report for {{ annual_report.file_date }}</span>
                <b-button
                  class="m-2"
                  @click="showFile(annual_reports, annual_report.id, 'Annual Report')"
                  variant="primary"
                  size="sm"
                >
                  <b-icon icon="eyeglasses" />
                    View
                </b-button>
                <b-button @click="downloadFile(annual_report.id)" variant="primary" size="sm">
                  <b-icon icon="download" />
                  Download
                </b-button>
              </li>
              </ul>
          </b-card-text>
        </b-tab>
        <b-tab lazy title="Inspection Reports">
          <b-card-text>
            <ul class="file-list">
              <li v-for="inspection in inspections" :key="inspection.id">
                &nbsp;<b-icon icon="file-text" />
                <span> Report for Inspection on {{ inspection.file_date }}</span>
                <b-button
                  class="m-2"
                  @click="showFile(inspections, inspection.id, 'Inspection Report')"
                  variant="primary"
                  size="sm"
                >
                  <b-icon icon="eyeglasses" />
                    View
                </b-button>
                <b-button @click="downloadFile(inspection.id)" variant="primary" size="sm">
                  <b-icon icon="download" />
                  Download
                </b-button>
              </li>
              </ul>
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-card>
    <PdfModal
      modal_id="pws_file_modal"
      :pdf_url="file_url"
      :pdf_title="file_title"
      />
  </div>
</template>

<script>
import PdfModal from "@/components/PdfModal.vue";

export default {
  name: 'PWSFiles',
  components: {
    PdfModal
  },
  data() {
    return {
      inspections: [],
      annual_reports: [],
      file_id: 0,
      file_url: '',
      file_title: '',
    }
  },
  created() {
    //this.getFiles()
    //this.getInspectionReports()
  },
  methods: {
    async getInspectionReports() {
      if (this.inspections.length > 0) {
        return
      }
      console.log('getInspectionReports')
      // Fetch files from server
      const path = "api/inspection-reports"
      let response = await this.axios.get(path, {
        params: { pws_id: this.pws_id }
      })
      console.log(response)
      if (response.data) {
        this.inspections = response.data
      }
    },
    async searchEfile(category_name) {
      console.log('searchEfile', category_name)
      const path = "api/efile-search"
      const params = { pws_id: this.pws_id, category_name: category_name }
      const response = await this.axios.post(path, params)
      console.log(response)
      return response.data
    },
    async getAnnualReports() {
      if (this.annual_reports.length > 0) {
        return
      }
      const annual_reports = await this.searchEfile('Annual Reports')
      this.annual_reports = annual_reports
    },
    async onTabChanged(tab) {
      console.log('onTabChanged', tab)
      if (tab === 0) {
        await this.getAnnualReports()
      } else if (tab === 1) {
        await this.getInspectionReports()
      }
      else {
        console.log('Unknown tab', tab)
      }
    },
    async getFileLink() {
      const path = "api/efile-link/" + this.file_id
      let response = await this.axios.get(path)
      console.log(response)
      if (response.data) {
        const file_link = response.data.file_link
        this.file_url = 'https://efile.mswater.us' + file_link
      }
    },
    async showFile(file_list, file_id, title_prefix) {
      this.file_id = file_id
      await this.getFileLink()
      const file_date = file_list.find(inspection => inspection.id === file_id).file_date
      this.file_title = `${title_prefix} for ${this.pws_label} on ${file_date}`
      this.$bvModal.show('pws_file_modal')
    },
    async downloadFile(file_id) {
      this.file_id = file_id
      await this.getFileLink()
      window.open(this.file_url, '_blank')
    },

  },
  computed: {
    pws_id() {
      return this.$store.state.pws_id
    },
    pws_label() {
      return `${this.$store.state.pws_id} -${this.$store.state.pws.pws_name}`;
    },
  },
}
</script>

<style>
  .file-list {
    padding-left: 0;
  }
  .file-list li {
    list-style-type: none;
  }
</style>
